export const FeedbackIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6625 1.66663H3.3375C2.42084 1.66663 1.67084 2.41663 1.67084 3.33329V18.3333L4.99584 15H16.6625C17.5792 15 18.3292 14.25 18.3292 13.3333V3.33329C18.3292 2.41663 17.5792 1.66663 16.6625 1.66663ZM10.8292 11.6666H9.1625V9.99996H10.8292V11.6666ZM10.8292 7.49996C10.8292 7.95829 10.4542 8.33329 9.99584 8.33329C9.5375 8.33329 9.1625 7.95829 9.1625 7.49996V5.83329C9.1625 5.37496 9.5375 4.99996 9.99584 4.99996C10.4542 4.99996 10.8292 5.37496 10.8292 5.83329V7.49996Z"
        fill="#003F2D"
      />
      <path
        d="M16.6625 1.66663H3.3375C2.42084 1.66663 1.67084 2.41663 1.67084 3.33329V18.3333L4.99584 15H16.6625C17.5792 15 18.3292 14.25 18.3292 13.3333V3.33329C18.3292 2.41663 17.5792 1.66663 16.6625 1.66663ZM10.8292 11.6666H9.1625V9.99996H10.8292V11.6666ZM10.8292 7.49996C10.8292 7.95829 10.4542 8.33329 9.99584 8.33329C9.5375 8.33329 9.1625 7.95829 9.1625 7.49996V5.83329C9.1625 5.37496 9.5375 4.99996 9.99584 4.99996C10.4542 4.99996 10.8292 5.37496 10.8292 5.83329V7.49996Z"
        fill="white"
        fillOpacity="0.08"
      />
    </svg>
  );
};
