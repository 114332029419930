import { Stack } from "@mui/material";
import Survey from "./Survey";

export default function NewDrawerPriority() {
  return (
    <Stack
      sx={{
        width: 480,
        padding: "20px",
        height: "100%",
      }}
      role="presentation"
    >
      <Survey />
    </Stack>
  );
}
