import { Button, Stack, Typography } from "@mui/material";
import { Error } from "@mui/icons-material";

/**
 * @param {{ onReload: React.MouseEventHandler<HTMLButtonElement> }} props
 */
export const ErrorCardContent = ({ onReload }) => {
  return (
    <Stack
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Error sx={{ fill: "#A03530" }} />
      <Typography
        variant="body2"
        sx={{ color: "#1A1A1AA6", mt: "4px", mb: "12px", textAlign: "center" }}
      >
        An error occurred while attempting to load the data
      </Typography>
      <Button onClick={onReload}>Reload</Button>
    </Stack>
  );
};
