import "./SnackbarContext.scss";
import { createContext, useState } from "react";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import React from "react";

const SnackbarContext = createContext(
  /**
   * @typedef {{
   *     message: string;
   *     title: string;
   *     severity?: import('@mui/material').AlertColor & import('@mui/material').AlertPropsColorOverrides;
   *     open: boolean;
   *     autoHideDuration: number;
   *     onClose?: () => void;
   *     anchorOrigin: import('@mui/material').SnackbarOrigin;
   * }} Snack
   * @type {null | { snack: Snack; setSnack: React.Dispatch<React.SetStateAction<Snack>> }}
   */
  (null),
);
export const useSnackbarContext = () => {
  const context = React.useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbarContext must be used within a SnackbarContext");
  }
  return context;
};
const SnackbarProvider = ({ children }) => {
  const [snack, setSnack] = useState(
    /** @type {Snack} */
    ({
      message: "",
      title: "",
      severity: "success",
      open: false,
      autoHideDuration: 3000,
      onClose: () => {},
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    }),
  );

  return (
    <SnackbarContext.Provider value={{ snack, setSnack }}>
      {children}
      <SnackbarComponent snack={snack} setSnack={setSnack} />
    </SnackbarContext.Provider>
  );
};

/**
 * @param {{
 *  snack: Snack;
 *  setSnack: React.Dispatch<React.SetStateAction<Snack>>;
 * }} param0
 */
function SnackbarComponent({ snack, setSnack }) {
  return (
    <Snackbar
      anchorOrigin={snack.anchorOrigin}
      autoHideDuration={snack.autoHideDuration}
      onClose={() => {
        if (snack.onClose) snack.onClose();
        setSnack((prev) => ({ ...prev, open: false }));
      }}
      open={snack.open}
      transitionDuration={0}
      sx={{ opacity: 1, paddingTop: "35px" }}
    >
      <Alert
        icon={<CheckCircle fontSize="inherit" sx={{ fill: "#50AF78" }} />}
        severity={snack.severity}
        onClose={() => {
          setSnack((prev) => ({ ...prev, open: false }));
        }}
        sx={{
          border: "1px solid #50AF78",
          borderColor: "1px solid #50AF78",
          width: "299px",
          height: "101px",
          opacity: 1,
          borderLeft: "5px solid #50AF78",
        }}
      >
        <AlertTitle
          sx={{
            fontFamily: "Calibre-R",
            fontSize: "16px",
            fontWeight: "600px",
            color: "#000000",
          }}
        >
          {snack.title}
        </AlertTitle>
        <div
          style={{
            fontFamily: "Calibre-R",
            fontSize: "15px",
            fontWeight: "400px",
            color: "#000000",
          }}
        >
          {snack.message}
        </div>
      </Alert>
    </Snackbar>
  );
}
export { SnackbarContext, SnackbarProvider };
