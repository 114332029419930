import { createContext, useState } from "react";

let SidebarContext;
const { Provider } = (SidebarContext = createContext());

const SidebarProvider = ({ children }) => {
  const localIsOpen =
    localStorage.getItem("navdrawerstateopen", "true") === "true";
  const [isOpen, setIsOpen] = useState(localIsOpen);
  return <Provider value={{ isOpen, setIsOpen }}>{children}</Provider>;
};

export { SidebarContext, SidebarProvider };
