import React, { useRef, useState, useEffect } from "react";
import { Stack, Box, IconButton } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { makeStyles } from "@mui/styles";
import { Link, useSearchParams } from "react-router-dom";
import RecommendedActions from "./RecommendedActions.jsx";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    margin: "24px 24px 0px 24px",
  },
  scrollContainer: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
  },
  stack: {
    paddingRight: "20px",
  },
  item: {
    display: "inline-block",
  },
  iconButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "#ffffff",
    border: "1px solid #e3e3e3",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  prevButton: {
    left: 0,
  },
  nextButton: {
    right: 0,
  },
}));

const RecommendedActionContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");
  const assetInsightsByPriority = useQuery({
    queryKey: ["getAssetInsights", recordId],
    queryFn: async () => {
      const data = (
        await api.post("issues/assetInsights", {
          site_ref: recordId,
          limit: 2,
          startDate: "2024-12-25T08:59:56.847Z",
          endDate: "2025-01-24T08:59:56.847Z",
          order: "priority",
        })
      ).data;
      if (data) {
        if (isAxiosError(data)) {
          if (data.response?.status === 401) {
            throw new Error(data.response.data.data);
          }
        } else {
          const firstSite = data?.data?.at(0)?.site;
          return firstSite;
        }
      }
      return null;
    },
  });
  const classes = useStyles();
  const items = Array.from({ length: 10 }, (_, index) => ({
    date: "17 Jan",
    title: "Assets Historical Issues",
    assetName: "FCU 101",
    alarmCounts: 18,
    issueCount: "4 P1s",
    recommendedAction:
      "Prioritize inspections and repairs immediately to prevent system failures and ensure occupant comfort Prioritize inspections and repairs immediately to prevent system failures and ensure occupant comfort.",
  }));
  const containerRef = useRef(null);
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(true);

  const checkScrollPosition = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setCanScrollPrev(scrollLeft > 0);
      setCanScrollNext(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scrollNext = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 400, behavior: "smooth" });
      setTimeout(checkScrollPosition, 400); // Check scroll position after animation
    }
  };

  const scrollPrev = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -400, behavior: "smooth" });
      setTimeout(checkScrollPosition, 400); // Check scroll position after animation
    }
  };

  useEffect(() => {
    checkScrollPosition();
    const handleScroll = () => checkScrollPosition();
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <Box className={classes.container}>
      <Box ref={containerRef} className={classes.scrollContainer}>
        <Stack direction="row" spacing={2} className={classes.stack}>
          {items.map((item, index) => (
            <Box key={index} className={classes.item}>
              <RecommendedActions {...item} />
            </Box>
          ))}
        </Stack>
      </Box>
      {canScrollPrev && (
        <IconButton
          onClick={scrollPrev}
          className={`${classes.iconButton} ${classes.prevButton}`}
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}
      {canScrollNext && (
        <IconButton
          onClick={scrollNext}
          className={`${classes.iconButton} ${classes.nextButton}`}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default RecommendedActionContainer;
