import "./AppLayout.scss";

import { styled } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import SideBar from "./SideBar.jsx";
import TopAppBar from "./TopAppBar";

import HTMLHead from "./HTMLHead";

import { SidebarContext } from "../../context/SidebarContext.jsx";
import NoInternet from "../noInternet/NoInternet.jsx";
import { Providers } from "./Providers.jsx";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: "56px !important",
}));

export function AppLayout() {
  const location = useLocation();
  const pathname = location.pathname;
  const { isOpen: open } = useContext(SidebarContext);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [collapsed, setCollapsed] = useState(!open);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(window.navigator.onLine);
    }

    function handleOnline() {
      window.location.reload();
    }

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const drawerCallback = (drawerStatus) => {
    setCollapsed(drawerStatus);
  };

  return (
    <Providers>
      <HTMLHead />
      <div className="main-container">
        <TopAppBar title="CBRE SmartFM" navigateTo="/" />
        {isOnline ? (
          <div id="layout-container">
            <SideBar
              parentCallback={drawerCallback}
              collapsed={collapsed}
              pathname={pathname}
            />

            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 0,
              }}
            >
              <DrawerHeader />
              <Outlet />
              <main className="subapp-container" id="subapp-container" />
            </Box>
          </div>
        ) : (
          <NoInternet />
        )}
      </div>
    </Providers>
  );
}
