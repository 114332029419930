import { Warning } from "@mui/icons-material";
import { Button, Skeleton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import TimePicker from "../../cards/TimePicker/TimePicker.jsx";
import "./IssuesWidget.scss";
import { useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import React, { useMemo } from "react";
import { DashboardContext } from "../../../context/DashboardContext.jsx";
import { ERROR_MSG, timeDurationOptions } from "../../../utils/constants.js";
import { getWidgetFilterInterval } from "../../../utils/functions.js";
import { ErrorCardContent } from "../../cards/ErrorCardContent.jsx";
import {
  CardTitle,
  WidgetContentForNoDataForTimeRange,
} from "../../cards/WidgetCard.jsx";
import BarChart from "../../charts/BarChart.jsx";
import { useNavigationCtx } from "../../NavigationProvider.jsx";
import { getIssuesWidgetData } from "./IssuesWidget.service.js";
const title = "Issues";
const _timeDurationOptions = timeDurationOptions.filter(
  (v) => v.label !== "Last 30 days",
);
const widgetType = "issues";
const icon = (
  <Warning
    sx={{
      height: "20px",
      width: "20px",
    }}
  />
);

export const IssuesWidget = () => (
  <div
    className="widget widget-n"
    style={{
      padding: "16px",
    }}
  >
    <IssuesWidgetContent />
  </div>
);
const IssuesWidgetContent = () => {
  const [dateRange, setDateRange] = React.useState(
    _timeDurationOptions.find((v) => v.label === "This month")?.label,
  );
  const interval = useMemo(
    () =>
      dateRange === "This month"
        ? "thisMonth"
        : dateRange === "This week"
          ? "thisWeek"
          : dateRange === "Last week"
            ? "lastWeek"
            : dateRange === "Last month"
              ? "lastMonth"
              : "last30Days",
    [dateRange],
  );
  const navigationCtx = useNavigationCtx();
  const { refreshDuration, refreshEvent } = React.useContext(DashboardContext);

  const projectId = navigationCtx?.state.selectedOption?.project_nexus_id;
  const buildingId = navigationCtx?.state.selectedOption?.nexusId;
  const buildingName = navigationCtx?.state.selectedOption?.navName;

  const useIssuesWidget = useQuery({
    queryKey: [widgetType, interval, buildingId, projectId, refreshEvent],
    enabled: !!widgetType && !!interval && !!buildingId && !!projectId,
    refetchInterval: 1000 * parseInt(refreshDuration) * 60,
    queryFn: () =>
      getIssuesWidgetData({ projectId, buildingId, widgetType, interval }),
  });
  const data = useMemo(() => {
    return (
      useIssuesWidget.data?.values.map((v) => ({
        name: v.field,
        value: v.value,
        color: "#80BBAD",
      })) || []
    );
  }, [useIssuesWidget.data]);
  const navigate = useNavigate();
  const link = useMemo(() => {
    if (
      !useIssuesWidget.data ||
      !buildingId ||
      !buildingName ||
      !projectId ||
      !useIssuesWidget.data.startDate ||
      !useIssuesWidget.data.endDate ||
      !interval
    ) {
      return null;
    }
    const dateRange = {
      from: useIssuesWidget.data.startDate.slice(0, 10),
      to: dayjs(useIssuesWidget.data.endDate).format("YYYY-MM-DD"),
    };
    const intervalType = getWidgetFilterInterval(interval);
    if (dateRange.from === "Invalid date" || dateRange.to === "Invalid date")
      return null;
    return `/ui/issue?recordType=site&view=issue&records=${buildingId}&recordId=${buildingName}&project=${projectId}&dateRange=${dateRange.from}%2C${dateRange.to}&intervalType=${intervalType}&sortField=latestEvent&sortOrientation=DESC&issueStatuses=All%2Cnew%2Cdispatched%2Cdispatch_closed%2Cdeferred%2Ccomplete%2Cauto_closed%2Creviewed%2Creview_complete&issueTypes=All%2Cemail%2Cspark&currentTab=issues`; // cspell:disable-line
  }, [buildingId, buildingName, interval, projectId, useIssuesWidget.data]);
  if (useIssuesWidget.isLoading)
    return (
      <div className="top" style={{ height: "100%" }}>
        <Skeleton variant="rectangular" height="100%" />
      </div>
    );
  if (useIssuesWidget.error) {
    if (isAxiosError(useIssuesWidget.error)) {
      return (
        <WidgetErrorReload
          onReload={() => useIssuesWidget.refetch()}
          icon={icon}
          link={link}
        />
      );
    }
    switch (useIssuesWidget.error.message) {
      case ERROR_MSG.widgetApiErrorMessage.noDataFoundForDateRange:
        return (
          <WidgetContentForNoDataForTimeRange
            timeDurationOptions={_timeDurationOptions ?? timeDurationOptions}
            title={title}
            link={link}
            dateRange={dateRange}
            setDateRange={setDateRange}
            icon={icon}
          />
        );
      case ERROR_MSG.widgetApiErrorMessage.noDataForRecord:
        return <NoDataAvailable title={title} icon={icon} />;
      case ERROR_MSG.widgetApiErrorMessage.buildingNotFound:
        return <WidgetErrorNoDataForBuilding icon={icon} title={title} />;
      default:
        return (
          <WidgetErrorReload
            onReload={() => useIssuesWidget.refetch()}
            icon={icon}
            link={link}
          />
        );
    }
  }
  if (!dateRange || !useIssuesWidget.data) {
    if (!dateRange)
      console.error("dateRange is not available in Issues widget");
    if (!useIssuesWidget.data)
      console.error("useIssuesWidget.data is not available in Issues widget");
    return (
      <WidgetErrorReload
        onReload={() => useIssuesWidget.refetch()}
        icon={icon}
        link={link}
      />
    );
  }
  return (
    <WidgetCard
      top={
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Button
            disabled={!link}
            sx={{
              borderRadius: "4px",
              height: "-webkit-fill-available",
              width: "100%",
              padding: "0px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            onClick={() => {
              if (!link) throw new Error("link is not available");
              navigate(link);
              navigate(0);
            }}
          >
            <Stack
              style={{
                height: "100%",
                width: "100%",
                flexDirection: "column",
                gap: "8px",
                justifyContent: "space-between",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                }}
              >
                {icon}
                <Typography
                  sx={{
                    // fontFamily: Calibre-R;
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    textAlign: "left",
                    textUnderlinePosition: "from-font",
                    textDecorationSkipInk: "none",
                    color: "#1A1A1AA6",
                  }}
                >
                  {title}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    lineHeight: "24px",
                    color: "#1A1A1A",
                  }}
                >
                  {`${useIssuesWidget.data.totalIssues} Issues`}
                </Typography>
              </Stack>
            </Stack>
          </Button>
          <Stack
            sx={{
              position: "absolute",
              right: 0,
              bottom: 0,
            }}
          >
            <TimePicker
              defaultVal={dateRange}
              onChangeDateRange={(newDateRange) => setDateRange(newDateRange)}
              timeDurationOptions={_timeDurationOptions}
              // sx={{
              //   '&.Mui-focused': {
              //     backgroundColor: '#ffffff'
              //   }
              // }}
            />
          </Stack>
        </div>
      }
      scrollContainer={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            flexGrow: 1,
            width: "-webkit-fill-available",
          }}
        >
          <Stack
            sx={{
              marginTop: "8px",
              marginBottom: "4px",
              flexDirection: "row",
              paddingBottom: "8px",
              justifyContent: "space-between",
              overflowY: "auto",
              // height: '20px',
              // scrollbarGutter: 'stable both-edges'
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#1A1A1AA6",
              }}
            >
              Issue name
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: "#1A1A1AA6",
              }}
            >
              Issue events
            </Typography>
          </Stack>
          <div
            style={{
              overflow: "auto",
              flexGrow: 1,
              height: "-webkit-fill-available",
              // scrollbarGutter: 'stable both-edges'
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            {useIssuesWidget.isLoading ? (
              <Skeleton variant="rectangular" height="100%" />
            ) : data.length > 0 ? (
              <BarChart data={data} />
            ) : null}
          </div>
        </div>
      }
    />
  );
};
const WidgetCard = ({ top, scrollContainer }) => (
  <>
    <div className="top">{top}</div>
    <div className="bottom" style={{ overflow: "auto" }}>
      {scrollContainer}
    </div>
  </>
);
const WidgetErrorReload = ({ onReload, icon, link }) => (
  <div className="top" style={{ height: "100%" }}>
    <div className="top">
      <CardTitle title={title} link={link} icon={icon} />
    </div>
    <ErrorCardContent onReload={onReload} />
  </div>
);
const WidgetErrorNoDataForBuilding = ({ icon, title }) => (
  <div className="top" style={{ height: "100%" }}>
    <div className="top">
      <CardTitle title={title} icon={icon} />
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="body2" color={"rgba(26, 26, 26, 0.65)"}>
        {"No data is available for this building"}
      </Typography>
    </div>
  </div>
);
const NoDataAvailable = ({ title, icon }) => (
  <div className="top" style={{ height: "100%" }}>
    <div className="top">
      <CardTitle title={title} icon={icon} />
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="body2" color={"rgba(26, 26, 26, 0.65)"}>
        {"No data is available"}
      </Typography>
    </div>
  </div>
);
