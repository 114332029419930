import { Close } from "@mui/icons-material";
import {
  Button,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { api } from "../../utils/Api";
import to from "../../utils/to";
import { useDrawerContext } from "./DrawerProvider";
import { useSnackbarContext } from "./SnackbarContext";

const defaultErrorMessage = "Something went wrong. Please try again later.";
const defaultSuccessMessage = "Your feedback has been submitted.";
const snackbarProps = {
  autoHideDuration: 5000,
  open: true,
  anchorOrigin: {
    horizontal: "right",
    vertical: "top",
  },
  onClose: () => {},
};

const maxLength = 1500;
const textAreaInputProps = { maxLength };

const Survey = () => {
  const { setDrawerComponent } = useDrawerContext();
  const [value, setValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const snackbar = useSnackbarContext();

  const handleSubmit = useCallback(
    async (message) => {
      setIsSubmitting(true);
      const [response, error] = await to(api.post("/feedback", { message }));

      if (error) {
        const message = error.message || defaultErrorMessage;
        snackbar.setSnack({
          ...snackbarProps,
          title: "Error",
          severity: "error",
          message,
        });
        return;
      }

      const { data } = response;

      if (!data.ok) {
        const message = data.error || defaultErrorMessage;
        snackbar.setSnack({
          ...snackbarProps,
          title: "Error",
          severity: "error",
          message,
        });
        return;
      }

      setIsSubmitting(false);
      setDrawerComponent(null);
      snackbar.setSnack({
        ...snackbarProps,
        title: "Thank you",
        severity: "success",
        message: defaultSuccessMessage,
      });
    },
    [setDrawerComponent, snackbar],
  );

  return (
    <div
      style={{
        flexGrow: "1",
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <div>
        <div
          style={{
            height: "100%",
            overflow: "auto",
            flexGrow: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="drawer-heading"
              style={{
                fontFamily: "Calibre-R",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "24px",
                letterSpacing: "-0.015em",
                textAlign: "left",
                color: "#000000",
              }}
            >
              Feedback
            </Typography>
            <IconButton onClick={() => setDrawerComponent(null)}>
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flexGrow: "1",
          }}
        >
          <div>
            <div>
              <Typography
                id="drawer-sub-heading"
                style={{
                  fontFamily: "Calibre-R",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0px",
                  textAlign: "left",
                  color: "#1A1A1A",
                  marginBottom: "20px",
                }}
              >
                Provide feedback about the SmartFM Solutions application. You
                can let our team know about bugs, feature ideas, and general
                input.
              </Typography>
            </div>

            <TextField
              label="How can we improve?"
              aria-label="minimum height"
              rows={10}
              multiline={true}
              inputProps={textAreaInputProps}
              placeholder="How can we improve?"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{
                width: "100%",
                color: "#1A1A1A",
              }}
              disabled={isSubmitting}
            />
            {value.length >= maxLength && (
              <FormHelperText>
                Character limit of {maxLength} reached.
              </FormHelperText>
            )}
          </div>
        </div>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "4px",
          }}
        >
          <div>
            <div>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "12px",
                  position: "relative",
                  flexGrow: 1,
                }}
              >
                <Button
                  id="drawer-cancel-button"
                  sx={{
                    color: "#003F2D",
                    textTransform: "none",
                  }}
                  onClick={() => setDrawerComponent(null)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSubmit(value)}
                  disabled={isSubmitting || !value.trim()}
                >
                  Submit
                </Button>
              </Stack>
            </div>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default Survey;
