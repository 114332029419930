const weatherIcons = {
  "clear sky": "https://openweathermap.org/img/wn/01d.png",
  "shower rain": "https://openweathermap.org/img/wn/09d.png",
  "scattered clouds": "https://openweathermap.org/img/wn/03d.png",
  rain: "https://openweathermap.org/img/wn/10d.png",
};

export const getWeatherIcon = (precipitation, cloudCover) => {
  let icon = "";
  if (precipitation == 0) {
    icon = "clear sky";
  }
  if (cloudCover > 0) {
    icon = "scattered clouds";
  }
  if (precipitation > 0 && precipitation < 7.5) {
    icon = "shower rain";
  }
  if (precipitation > 7.5) {
    icon = "rain";
  }
  return weatherIcons[icon];
};
