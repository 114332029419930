import "./Icons.scss";

import Box from "@mui/material/Box";

const AccountCircle = () => {
  return <span className="material-symbols-outlined">account_circle</span>;
};

const AdminPanelSettings = () => {
  return (
    <span className="material-symbols-outlined">admin_panel_settings</span>
  );
};

const Alarm = () => {
  return <span className="material-symbols-outlined">alarm</span>;
};

const CleaningServices = () => {
  return <span className="material-symbols-outlined">cleaning_services</span>;
};

const Construction = () => {
  return <span className="material-symbols-outlined">construction</span>;
};

const ContactMail = () => {
  return <span className="material-symbols-outlined">contact_mail</span>;
};

const Handyman = () => {
  return <span className="material-symbols-outlined">handyman</span>;
};

const Logout = (props) => {
  return (
    <Box component="span" className="material-symbols-outlined" sx={props.sx}>
      logout
    </Box>
  );
};

const ModeFan = () => {
  return <img className="material_symbol_mode_fan" src="/icons/Mode_Fan.svg" />;
};

const Notifications = () => {
  return <span className="material-symbols-outlined">notifications</span>;
};

const PieChart = () => {
  return <span className="material-symbols-outlined">pie_chart</span>;
};

const Settings = () => {
  return <span className="material-symbols-outlined">settings</span>;
};

const ChevronLeft = () => {
  return <span className="material-symbols-outlined">chevron_left</span>;
};

const ChevronRight = () => {
  return <span className="material-symbols-outlined">chevron_right</span>;
};

const RightArrow = () => {
  return <span className="material-symbols-outlined">arrow_right_alt</span>;
};

const Menu = () => {
  return <span className="material-symbols-outlined">menu</span>;
};

const Warning = () => {
  return <span className="material-symbols-outlined">warning</span>;
};

const Done = () => {
  return (
    <span
      className="material-symbols-outlined"
      style={{
        fontSize: "14px",
        color: "#1A1A1A99",
        float: "right",
        marginLeft: "auto",
        marginRight: "0",
      }}
    >
      done
    </span>
  );
};

const Dashboard = () => {
  return <span className="material-symbols-outlined">dashboard</span>;
};

const ReflowServices = () => {
  return <span className="material-symbols-outlined">reflow services</span>;
};

const VantageUI = () => {
  return <span className="material-symbols-outlined">vantage ui</span>;
};

export {
  AccountCircle,
  AdminPanelSettings,
  Alarm,
  CleaningServices,
  Construction,
  ContactMail,
  Handyman,
  Logout,
  ModeFan,
  Notifications,
  PieChart,
  Settings,
  ChevronLeft,
  ChevronRight,
  RightArrow,
  Menu,
  Warning,
  Done,
  Dashboard,
  ReflowServices,
  VantageUI,
};
