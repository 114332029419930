const validEnvs = ["local", "development", "qa", "uat", "production"] as const;

type ValidEnvs = (typeof validEnvs)[number];

function isValidEnv<T extends ValidEnvs>(env: string): env is T {
  return validEnvs.some((validEnv) => validEnv === env);
}

export function isServer() {
  return "process" in globalThis;
}

export function getEnv<T extends ValidEnvs>() {
  const nxEnv = isServer()
    ? `${globalThis.process.env.NX_ENV}`
    : `${globalThis.__NX_ENV__}`; // this is the window object on client side
  // Assuming that the env has a format of `{env}-smartfm`
  const env = nxEnv.split("-").at(0) || "";

  return isValidEnv<T>(env) ? env : "local";
}

export function isLocal() {
  return getEnv() === "local";
}

export function isDev() {
  return getEnv() !== "production";
}
