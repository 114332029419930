// cspell:ignore ALTG BLTG CLTG DBTLGLLBLTG DBTLGLLBTL TEFLG
import { api } from "../../../utils/Api.js";
import { ERROR_MSG } from "../../../utils/constants.js";
import * as z from "zod";

const isUsingMockData = process.env.NODE_ENV === "development" && false;

export const mockData = {
  status: "success",
  data: {
    widgetType: "issues",
    interval: "thisMonth",
    buildingId: "p:cbreib:r:2c1b5b97-a6e0b7ba",
    projectId: "CBRE Offices",
    startDate: "2024-12-01",
    endDate: "2024-12-31",
    values: [
      {
        field: "PM Spare11 - Communication Failure",
        value: 13,
      },
      {
        field: "PM T03ATL - Communication Failure",
        value: 6,
      },
      {
        field: "PM T06CTL - Communication Failure",
        value: 6,
      },
      {
        field: "AHU 7F01 - Data Missing",
        value: 6,
      },
      {
        field: "AHU UG01 - Equipment Running Too Long",
        value: 6,
      },
      {
        field: "PM DBTLGLLBLTG - Communication Failure",
        value: 6,
      },
      {
        field: "PM T05ALTG - Data Double Dipping",
        value: 6,
      },
      {
        field: "AHU 2F01 - Data Missing",
        value: 6,
      },
      {
        field: "VGM ALL - Communication Failure",
        value: 6,
      },
      {
        field: "EF TEFLG02 - Data Double Dipping",
        value: 6,
      },
      {
        field: "AHU UG04 - Communication Failure",
        value: 6,
      },
      {
        field: "PM T02ALTG - Communication Failure",
        value: 6,
      },
      {
        field: "PM T01CLTG - Communication Failure",
        value: 6,
      },
      {
        field: "PM T03CTL - Communication Failure",
        value: 6,
      },
      {
        field: "PM T02CLTG - Communication Failure",
        value: 6,
      },
      {
        field: "PM T01CTL - Communication Failure",
        value: 6,
      },
      {
        field: "PM T01ALTG - Data Double Dipping",
        value: 6,
      },
      {
        field: "PM T06ALTG - Data Double Dipping",
        value: 6,
      },
      {
        field: "AHU 1F01 - Equipment Running Too Long",
        value: 6,
      },
      {
        field: "PM T02BTL - Communication Failure",
        value: 6,
      },
      {
        field: "PM T06ATL - Communication Failure",
        value: 6,
      },
      {
        field: "PM T03CLTG - Communication Failure",
        value: 6,
      },
      {
        field: "PM T06BLTG - Communication Failure",
        value: 6,
      },
      {
        field: "AHU UG04 - Data Missing",
        value: 6,
      },
      {
        field: "PM T02CTL - Communication Failure",
        value: 6,
      },
      {
        field: "PM DBTLGLLBTL - Communication Failure",
        value: 6,
      },
      {
        field: "PM T05ATL - Communication Failure",
        value: 6,
      },
      {
        field: "PM T04BTL - Data Double Dipping",
        value: 6,
      },
      {
        field: "AHU RF01 Reclaim Pump - AHU Heating Valve Leaking",
        value: 1,
      },
      {
        field:
          "FCU_3F0203 serving [Floor 3 Group 02 Unit 3] zoneTemp High Alarm",
        value: 1,
      },
      {
        field:
          "FCU_4F0205 serving [Floor 4 Group 02 Unit 5] zoneTemp High Alarm",
        value: 1,
      },
    ],
    totalIssues: 31,
  },
};
/**
 * @param {{
 *  projectId?: string | null;
 *  buildingId?: string;
 *  widgetType: string;
 *  interval: string;
 * }} param0
 */
export const getIssuesWidgetData = async ({
  projectId,
  buildingId,
  widgetType,
  interval,
}) => {
  const responseData = apiValidation(
    (isUsingMockData
      ? mockData
      : await api.get(
          `issues/projects/${projectId}/buildings/${buildingId}/widgets`,
          {
            params: {
              widgetType,
              interval,
            },
          },
        )
    ).data,
  );
  if (responseData.status === "error")
    throw new Error(
      responseData.errorMessage || ERROR_MSG.errorMessageNotFound,
    );
  if (!responseData.data) throw new Error(ERROR_MSG.dataPropNotFoundInBody);
  return responseData.data;
};

/**
 * @param {unknown} responseBody
 */
function apiValidation(responseBody) {
  const schema = z.discriminatedUnion("status", [
    z.object({ errorMessage: z.string(), status: z.literal("error") }),
    z.object({
      data: z.object({
        buildingId: z.string(),
        endDate: z.string(),
        interval: z.string(),
        projectId: z.string(),
        startDate: z.string(),
        totalIssues: z.number(),
        values: z.array(
          z.object({
            field: z.string(),
            value: z.number(),
          }),
        ),
        widgetType: z.string(),
      }),
      status: z.literal("success"),
    }),
  ]);
  const parseResult = schema.safeParse(responseBody);
  if (parseResult.error) {
    const error = parseResult.error.errors.at(0) || {
      message: parseResult.error.message,
      path: [],
    };
    throw `validation error \n path = ${JSON.stringify(
      error.path,
    )} \n error=${error.message}`;
  }
  return parseResult.data;
}
