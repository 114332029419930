import { useCallback, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import "./PieChart.scss";

const COLORS = ["#50AF78", "#E3E3E3"];
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        fill={fill}
      />
    </g>
  );
};

export default function ({ data }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );

  return (
    <ResponsiveContainer>
      <PieChart
        height="75%"
        margin={{ top: 10, left: 10, right: 10, bottom: 10 }}
      >
        <Pie
          isAnimationActive={false}
          id="assets-current-avg-asset-health-pie"
          activeIndex={activeIndex}
          data={data}
          // cx={200}z
          // cy={200}
          startAngle={-270}
          innerRadius={"60%"}
          dataKey="value"
          // onMouseEnter={onPieEnter}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              id={`assets-current-avg-asset-health-data-${index}`}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
