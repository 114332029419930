import { useEffect } from "react";
import OktaAuth from "../utils/okta.js";

export default function LogoutPage() {
  useEffect(() => {
    OktaAuth.logout();
  }, []);

  return (
    <>
      <span
        style={{
          position: "absolute",
          top: "20%",
          left: "42%",
          zIndex: 100,
          fontSize: "30px",
          fontWeight: "800",
        }}
      >
        {" "}
        Until next time...{" "}
      </span>
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden !important",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            height: "120vh",
            width: "200vw",
            overflow: "hidden !important",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        ></div>
      </div>
    </>
  );
}
