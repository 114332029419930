import { initGlobalState } from "qiankun";

const initialStateDefaults = {
  project: "Merck",
  user: {
    firstname: "SmartFM",
    lastname: "User",
    locale: "en_US",
    tz: "America/Chicago",
  },
};

let globalState;

/* Override initGlobalState to get localStorage */
let actions;
const _initGlobalState = () => {
  globalState = localStorage.getItem("global-state");
  if (!globalState) {
    globalState = initialStateDefaults;
    localStorage.setItem("global-state", JSON.stringify(globalState));
  } else {
    globalState = JSON.parse(globalState);
  }
  actions = initGlobalState(globalState);
};
_initGlobalState();

actions.onGlobalStateChange((state, prev) => {
  for (const key in state) {
    globalState[key] = state[key];
  }
  localStorage.setItem("global-state", JSON.stringify(globalState));
});

actions.getGlobalState = (key = null) => {
  return key ? globalState[key] : globalState;
};

/* Override setGlobalState function to set localStorage */
const _setGlobalState = actions.setGlobalState;
actions.setGlobalState = (state) => {
  _setGlobalState(state);
  localStorage.setItem("global-state", JSON.stringify(state));
};

export default actions;
