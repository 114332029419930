export const getWidgetFilterInterval = (interval) => {
  return interval === "thisMonth" || interval === "lastMonth"
    ? "Monthly"
    : interval === "thisWeek" || interval === "lastWeek"
      ? "Weekly"
      : interval == "last30Days"
        ? "Last 30 days"
        : "All";
};

export const getWidgetMapping = (widget) => {
  switch (widget) {
    case "9":
      return "issues";
    case "11":
      return "weather";
    case "8":
      return "health";
    case "5":
      return "legionellaCompliance";
    // Add more cases for other categories if needed
    default:
      return null;
  }
};
