import { useEffect, useRef, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import "./index.scss";

import { AppLayout } from "./components/layouts/AppLayout";
import { HomePage } from "./pages/Home";
import LoginPage from "./pages/Login";
import LogoutPage from "./pages/Logout";
import OktaAuth, { getUserData } from "./utils/okta";

import { isLocal } from "#utils/env";
import { config } from "./config";
import { NavigationProvider } from "./components/NavigationProvider";
import { SidebarProvider } from "./context/SidebarContext";
import { TanstackReactQueryProvider } from "./TanstackReactQueryProvider";
import { identifyUserForAnalytics } from "./utils/analytics";
import Api from "./utils/Api";

const { adminServiceUrl } = config;

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  /**
   * Reference to hold authentication promise
   * @type {React.MutableRefObject<Promise<void> | null>}
   */
  const authPromiseRef = useRef(null);

  useEffect(() => {
    if (!authPromiseRef.current) {
      authPromiseRef.current = new Promise((resolve) => {
        OktaAuth.init(async (authState) => {
          const { isAuthenticated } = authState;
          if (!isAuthenticated) return;
          resolve();

          // Update user last login
          Api.patch(`${adminServiceUrl}/updateUserLastLogin`);

          // Do not initialize analytics on local
          if (isLocal()) return;

          // Identify user for analytics
          const { email } = getUserData();
          if (!email) return;
          identifyUserForAnalytics(email);
        });
      });

      bootstrap();
    }

    async function bootstrap() {
      if (!OktaAuth.isAuthenticated()) {
        try {
          await authPromiseRef.current;
        } catch (error) {
          console.error("Authentication failed:", error);
          return;
        }
      }
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <TanstackReactQueryProvider>
      <NavigationProvider>
        <SidebarProvider>
          {isAuthenticated ? (
            <Router>
              <Routes>
                <Route
                  path="/login/oidc/callback"
                  element={<LoginPage isAuthenticated={true} />}
                />
                <Route path="/logout/oidc/callback" element={<LogoutPage />} />
                <Route path="/" element={<AppLayout />}>
                  <Route index element={<HomePage />} />
                  <Route path="ui/issue/*" subapp={true} />
                  <Route path="ui/admin/*" subapp={true} />
                  <Route path="ui/maintenance/*" subapp={true} />
                  <Route path="ui/moe/*" subapp={true} />
                  <Route path="ui/vantage-dashboard/*" subapp={true} />
                  <Route path="ui/reflow/*" subapp={true} />
                  <Route path="ui/cbm/*" subapp={true} />
                  <Route path="ui/smart-cleaning/*" subapp={true} />
                  <Route path="ui/pipe-monitoring/*" subapp={true} />
                  <Route path="*" element={<div>Not Found</div>} />
                </Route>

                {/* provide demo routes as physical page */}
                <Route path="/demo" element={<AppLayout />}>
                  <Route index element={<HomePage />} />
                  <Route path="/demo/ui/issue/*" subapp={true} />
                  <Route path="/demo/ui/admin/*" subapp={true} />
                  <Route path="/demo/ui/maintenance/*" subapp={true} />
                  <Route path="/demo/ui/moe/*" subapp={true} />
                  <Route path="/demo/ui/vantage-dashboard/*" subapp={true} />
                  <Route path="/demo/ui/reflow/*" subapp={true} />
                  <Route path="/demo/ui/cbm/*" subapp={true} />
                  <Route path="/demo/ui/smart-cleaning/*" subapp={true} />
                  <Route path="/demo/ui/pipe-monitoring/*" subapp={true} />
                  <Route path="*" element={<div>Not Found</div>} />
                </Route>
              </Routes>
            </Router>
          ) : (
            <Router>
              <Routes>
                <Route path="/login/oidc/callback" element={<LoginPage />} />
                <Route path="/logout/oidc/callback" element={<LogoutPage />} />
                <Route path="*" element={<LoginPage />} />
              </Routes>
            </Router>
          )}
        </SidebarProvider>
      </NavigationProvider>
    </TanstackReactQueryProvider>
  );
}
