import { OktaAuth } from "@okta/okta-auth-js";

import GlobalState from "../store.js";

import { config } from "@/config";
const oktaCfg = config.okta;

/**
 * @type {import('@okta/okta-auth-js').OktaAuth | null}
 */
let authClient = null;
let AUTHENTICATED = false;

/* Function to trigger login */
const triggerLogin = () => {
  authClient.signInWithRedirect();
};

const getUserData = () => {
  const oktaJsonData = localStorage.getItem("okta-token-storage");
  if (!oktaJsonData) return {};

  const oktaData = JSON.parse(oktaJsonData);
  const userData = {
    email: oktaData.idToken?.claims.unique_name,
    firstname: oktaData.idToken?.claims.name.split(" ")[0],
    lastname: oktaData.idToken?.claims.name.split(" ")[1],
    aud: oktaData.idToken?.claims.unique_name,
  };

  const globalState = GlobalState.getGlobalState();
  globalState.user = userData;
  GlobalState.setGlobalState(globalState);

  return userData;
};

const unsecuredRoutes = ["/logout/oidc/callback"];

export { getUserData };

export default {
  isAuthenticated: () => {
    return AUTHENTICATED;
  },
  logout: async () => {
    if (!authClient) return;
    authClient.tokenManager.clear();
    await authClient.signOut();
    authClient = null;
  },
  init: async (authEventNotify = () => null) => {
    const oktaConfig = {
      issuer: oktaCfg.issuer,
      clientId: oktaCfg.clientId,
      redirectUri: oktaCfg.redirectUri,
      tokenUrl: "/login/oidc/token",
      scopes: ["openid", "profile", "email"],
      responseType: "code",
      pkce: true,
    };

    // create OktaAuth instance
    authClient = new OktaAuth(oktaConfig);
    // Subscribe to authState change event.
    authClient.authStateManager.subscribe(function (authState) {
      if (unsecuredRoutes.includes(window.location.pathname)) {
        return;
      }

      authEventNotify(authState);
      // Logic based on authState is done here.
      if (!authState.isAuthenticated) {
        AUTHENTICATED = false;
        triggerLogin();
        return;
      } else {
        AUTHENTICATED = true;
      }

      // Render authenticated view
    });

    authClient.tokenManager.on("expired", (key) => {
      authClient.tokenManager.renew(key).then(() => {
        window.location.reload();
      });
    });
    // Handle callback
    if (authClient.token.isLoginRedirect()) {
      try {
        const { tokens } = await authClient.token.parseFromUrl();
        authClient.tokenManager.setTokens(tokens);
      } catch (error) {
        console.error("Error parsing tokens from URL: ", error);
        window.location.replace("/");
      }
    }
    // will update auth state and call event listeners
    await authClient.start();

    return authClient;
  },
};
