import { Cascader } from "@smartfm/react-components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { api } from "./../utils/Api";
import { useNavigationCtx } from "./NavigationProvider";
import { Button } from "@mui/material";
import { setDefaultDashboard } from "../services/DashboardService";
import { isAxiosError } from "axios";

export function CascaderInstance() {
  const appName = "Container UI";
  const [searchParams, setSearchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");
  const navigationCtx = useNavigationCtx();

  const defaultDashboard = useQuery({
    queryKey: ["getDefaultSiteForUser"],
    queryFn: async () => {
      /**
       * @type {{
       *     "message": string,
       *     "error": boolean,
       *     "data": [
       *         {
       *             "site": string,
       *             "userId": string,
       *             "client": string,
       *             "widgets": unknown,
       *             "default": boolean
       *         }
       *     ]
       * }}
       */
      const data = (
        await api.post("admin/getDefaultSiteForUser", {
          defaultDashboard: true,
        })
      ).data;
      if (data) {
        if (isAxiosError(data)) {
          if (data.response?.status === 401) {
            throw new Error(data.response.data.data);
          }
        } else {
          const firstSite = data?.data?.at(0)?.site;
          return firstSite;
        }
      }
      return null;
    },
  });

  const useQueryResult = useQuery({
    queryKey: ["cascadenavigation", recordId, appName],
    queryFn:
      /**
       * @returns {Promise<{ data: import("@smartfm/react-components").NavNode }>}
       */
      () =>
        api.post("navigation/cascadenavigation", {
          id: recordId === "null" ? null : recordId,
          cascader: recordId === "null" ? "true" : "false",
          appName,
        }),
    enabled: !!recordId,
  });
  const useMutationResult = useMutation({
    mutationKey: ["cascadenavigation", appName],
    mutationFn:
      /**
       * @param {string} id
       */
      async (id) => {
        try {
          return (
            await api.post("navigation/cascadenavigation", {
              id,
              cascader: "true",
              appName,
            })
          ).data;
        } catch (error) {
          return null;
        }
      },
  });
  const useMutationDefaultDashboard = useMutation({
    mutationKey: ["defaultdashboard"],
    mutationFn: () => {
      try {
        return setDefaultDashboard(
          navigationCtx?.state.selectedOption.project_nexus_id,
          navigationCtx?.state.selectedOption.nexusId,
        );
      } catch (err) {
        return Promise.resolve(null);
      }
    },
    onSuccess: () => defaultDashboard.refetch(),
  });

  const setInitialId = useCallback(
    (id) => {
      searchParams.set("recordId", id || "null");
      searchParams.delete("tab");
      setSearchParams(searchParams, { replace: false });
      useQueryResult.refetch();
    },
    [searchParams, setSearchParams],
  );

  const setSelectedOption = useCallback(
    (selectedOption) =>
      navigationCtx?.setState((prev) => ({ ...prev, selectedOption })),
    [navigationCtx?.setState],
  );
  const setAllOptions = useCallback(
    (allOptions) =>
      navigationCtx?.setState((prev) => ({ ...prev, allOptions })),
    [navigationCtx?.setState],
  );
  const onSelectedOptionChange = useCallback(
    (option) => setSelectedOption(option),
    [setSelectedOption],
  );
  const onAllOptionsChange = useCallback(
    (options) => setAllOptions(options),
    [setAllOptions],
  );
  const setBuildingAsDefault = () => useMutationDefaultDashboard.mutate();

  useEffect(() => {
    if (!recordId && defaultDashboard.data) {
      searchParams.set("recordId", defaultDashboard.data);
      setSearchParams(searchParams, { replace: false });
    }
  }, [defaultDashboard.data, recordId]);

  return (
    <div
      style={{
        height: "136px",
        paddingBottom: "16px",
      }}
    >
      {defaultDashboard.data === null ||
      isAxiosError(defaultDashboard.data) ? null : (
        <Cascader
          Link={Link}
          initialId={recordId === "null" ? null : recordId}
          onSelectedOptionChange={onSelectedOptionChange}
          onAllOptionsChange={onAllOptionsChange}
          useQueryResult={useQueryResult}
          useMutationResult={useMutationResult}
          setInitialId={setInitialId}
          secondaryComponentFn={(o) =>
            !o ? null : navigationCtx?.state.selectedOption?.nexusId ===
                (recordId === "null" ? null : recordId) &&
              navigationCtx?.state?.selectedOption?.navType == "site" ? (
              <Button
                size="small"
                sx={{
                  textTransform: "none",
                }}
                disabled={
                  defaultDashboard.data ===
                  (recordId === "null" ? null : recordId)
                }
              >
                {defaultDashboard.data ===
                (recordId === "null" ? null : recordId) ? (
                  "Default dashboard"
                ) : (
                  <u onClick={() => setBuildingAsDefault()}>Set as default</u>
                )}
              </Button>
            ) : (
              ""
            )
          }
        />
      )}
    </div>
  );
}
