import Api from "../utils/Api.js";

export async function getInterval() {
  try {
    const response = await Api.get("dashboard/interval");
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function saveRefreshInterval(intervalInMins) {
  try {
    const data = JSON.stringify({
      interval: intervalInMins,
    });
    const response = await Api.post("dashboard/interval", data);
    return response;
  } catch (error) {
    return [];
  }
}
/**
 *
 * @param {string} client
 * @param {string} siteId
 */
export async function setDefaultDashboard(client, siteId) {
  try {
    const data = JSON.stringify({
      client,
      siteId,
      defaultDashboard: true,
    });
    /** @type {import("axios").AxiosResponse<{message:string;error:boolean}>} */
    const response = await Api.post("AddUserDefaultDashboard", data);
    return response.data;
  } catch (error) {
    return [];
  }
}

/**
 *
 * @param {string} client
 */
export async function getWidgetListByClient(client) {
  try {
    /** @type {import("axios").AxiosResponse<{message:string;error:boolean}>} */
    if (!client) throw new Error("Client is required");
    const response = await Api.get(`getWidgetList/${client}`);
    return response.data;
  } catch (error) {
    return [];
  }
}
