import { getEnv } from "#utils/env";
import { datadogRum } from "@datadog/browser-rum";
import { config } from "@/config";
import { getUserData } from "@/utils/okta";

interface UserData {
  email: string;
  firstname: string;
  lastname: string;
}

function isUserData(data: unknown): data is UserData {
  return (
    data !== null &&
    typeof data === "object" &&
    "email" in data &&
    "firstname" in data &&
    "lastname" in data
  );
}

export async function initDatadogRum() {
  const isProduction = getEnv() === "production";
  const [applicationId, clientToken] = isProduction
    ? [
        import.meta.env.VITE_DATADOG_APPLICATION_ID_PROD,
        import.meta.env.VITE_DATADOG_CLIENT_TOKEN_PROD,
      ]
    : [
        import.meta.env.VITE_DATADOG_APPLICATION_ID,
        import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      ];

  if (!(applicationId && clientToken)) return;

  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.com",
    service: "smart_fm",
    env: window.__NX_ENV__,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 60,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  const userData = getUserData();
  if (isUserData(userData)) {
    const { email, firstname, lastname } = userData;
    const userId = (await generateUserIdFromEmail(email)) ?? undefined;
    const userName =
      [lastname, firstname].filter(Boolean).join(" ") || undefined;

    datadogRum.setUser({
      id: userId,
      name: userName,
      email: email || undefined,
    });
  } else {
    console.error("User data for RUM is missing.");
  }
}

async function generateUserIdFromEmail(email: string | undefined) {
  if (!email) return undefined;

  const { uuidGeneratorService } = config;
  const { baseUrl, params } = uuidGeneratorService;

  const queryParams = new URLSearchParams(params);
  queryParams.set("email", email);
  const url = `${baseUrl}?${queryParams.toString()}`;

  try {
    const response = await fetch(url);
    if (!response.ok) return undefined;
    return await response.text();
  } catch (error) {
    console.error("Error generating UUID for RUM:", error);
    return undefined;
  }
}
