import "./BarChart.scss";

export default function BarChart({ data }) {
  const highestVal = Math.max(...data.map((d) => d.value));
  if (data.length === 0)
    return (
      <div
        className="no-data"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          textAlign: "center",
          color: "rgba(26, 26, 26, 0.65)",
        }}
      >
        No data available
      </div>
    );
  return (
    <div className="bar-chart scroll-container">
      {data.map((entry, index) => (
        <div className="bar-item" key={index}>
          <div
            className="bar-bg"
            style={{
              backgroundColor: entry.color,
              width: `${(entry.value / highestVal) * 100}%`,
            }}
          ></div>
          <div
            className="bar-label"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              textWrap: "nowrap",
            }}
          >
            {entry.name}
          </div>
          <div className="bar-value">{entry.value}</div>
        </div>
      ))}
    </div>
  );
}
