/**
 * @template T
 * @template U
 * @param {Promise<T>} promise
 * @return {Promise<[[T, null] | undefined, U]>}
 */
export async function to(promise) {
  try {
    const data = await promise;
    return [data, null];
  } catch (error) {
    return [undefined, error];
  }
}

export default to;
