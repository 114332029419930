import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { DashboardContext } from "../../../context/DashboardContext.jsx";
import { useNavigationCtx } from "../../NavigationProvider.jsx";
import { WidgetCard, dateRangeId } from "../WidgetCard.jsx";
import "../widgets.scss";
import { api } from "./../../../utils/Api.js";
import "./Weather.scss";
import { getWeatherIcon } from "./WeatherIcon.jsx";
import { Stack } from "@mui/material";

export default function Weather() {
  const title = "Weather";
  const widgetType = "weather";
  const { refreshDuration, refreshEvent } = React.useContext(DashboardContext);
  const dateRange = "This week";
  const navigationCtx = useNavigationCtx();
  const interval = dateRangeId(dateRange);
  const projectId = navigationCtx?.state.selectedOption?.project_nexus_id;
  const buildingId = navigationCtx?.state.selectedOption?.nexusId;
  const query = useQuery({
    queryKey: [widgetType, interval, buildingId, projectId, refreshEvent],
    enabled: !!widgetType && !!interval && !!buildingId && !!projectId,
    refetchInterval: 1000 * parseInt(refreshDuration) * 60,
    queryFn:
      /**
       * @typedef {{
       *     widgetType: string;
       *     interval: string;
       *     buildingId: string;
       *     projectId: string;
       *     startDate: string;
       *     endDate: string;
       *     values: {
       *         field: string;
       *         units: string;
       *         min: number|null;
       *         max: number|null;
       *         avg: number|null;
       *         precipitation: number|null;
       *     }[];
       *     minIntervalTemp: number|null;
       *     maxIntervalTemp: number|null;
       *     avgIntervalTemp: number|null;
       *     avgLastIntervalTemp: number|null;
       *     avgIntervalPrecipitation: number|null;
       *     deviation: number|null;
       *     comparison: string|null;
       *     arrow: null | "up" | "down";
       *     unit: string;
       * }} APIResponseData
       * @typedef {'No data for date range' | 'No data for record'} APIResponseErrorMessage
       * @returns {import("./../types.js").QueryData<APIResponseData, APIResponseErrorMessage>}
       */
      async () => {
        try {
          /**
           * @type {import('axios').AxiosResponse<{
           *      status?: "success" | "error";
           *      data?: APIResponseData;
           *      errorMessage?: APIResponseErrorMessage;
           * }, any>}
           * */
          const response = await api.get(
            `assets/projects/${projectId}/buildings/${buildingId}/widgets`,
            {
              params: {
                widgetType,
                interval,
              },
            },
          );
          if (response.data.status === "error")
            if (response.data.errorMessage)
              return {
                status: "error",
                errorMessage:
                  response.data.errorMessage === "No data for date range" ||
                  response.data.errorMessage === "No data for record"
                    ? response.data.errorMessage
                    : "unknown error",
              };
            else
              throw new Error(
                "API response status is 'error' without any 'errorMessage'",
              );
          if (!response.data.data)
            throw new Error("Unable to find 'data' property in API response");

          if (response.data.data.avgIntervalTemp === null) {
            throw new Error(
              "'avgIntervalTemp' property in API response was found to be null",
            );
          }
          return { status: "success", data: response.data.data };
        } catch (e) {
          console.error(e || "some error occurred");
          return {
            status: "error",
            errorMessage: "unknown error",
          };
        }
      },
  });

  return (
    <WidgetCard
      icon={
        <div
          className="app-icon"
          style={{
            display: "block",
            height: "20px",
            ...(query.data?.status === "success"
              ? {
                  backgroundImage: `url(${getWeatherIcon(
                    query.data?.data?.avgIntervalPrecipitation,
                  )})`,
                }
              : {}),
            backgroundRepeat: "no-repeat",
            backgroundSize: "150%",
            width: "25px",
            backgroundPosition: "center",
          }}
        ></div>
      }
      scrollContainer={
        <div
          className="bottom"
          style={{
            overflow: "auto",
          }}
        >
          {query.data?.status === "success"
            ? query.data.data.values.map((weekData) => {
                return (
                  <WeekDay
                    key={weekData.field}
                    label={weekData.field}
                    max={weekData.max}
                    min={weekData.min}
                    unit={weekData.units}
                    precipitation={weekData.precipitation}
                  />
                );
              })
            : null}
        </div>
      }
      useQueryResult={query}
      title={title}
      headerValueComponent={
        query.data?.status === "success" ? (
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="h5" color="#1A1A1A">
              {`${query.data.data.avgIntervalTemp}${query.data.data.unit}`}
            </Typography>
            <Typography variant="body2" color={"#1A1A1AA6"}>
              {`${query.data.data.maxIntervalTemp}${query.data.data.unit} / ${query.data.data.minIntervalTemp}${query.data.data.unit}`}
            </Typography>
          </Stack>
        ) : null
      }
      valueChangeComponent={
        query.data?.status === "success" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: 14 }}
            >
              {query.data.data.arrow === "up"
                ? "arrow_upward"
                : "arrow_downward"}
            </span>
            {query.data.data.comparison || query.data.data.deviation}
          </div>
        ) : null
      }
    />
  );
}

function WeekDay({ label, max, min, unit, precipitation }) {
  return (
    <div
      className="day"
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "20px",
          color: "#1A1A1A",
          letterSpacing: 0,
          textAlign: "center",
          marginBottom: "8px",
        }}
      >
        {label}
      </Typography>
      <div
        className="day-icon"
        style={{
          height: "50px",
          backgroundImage: `url(${getWeatherIcon(precipitation)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto 100%",
          width: "100%",
          backgroundPosition: "center",
          marginBottom: "8px",
        }}
      ></div>
      <div
        className="day-temperature"
        style={{
          height: "45px",
          width: "57px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#1A1A1A",
          }}
        >
          {max === null ? "-" : `${max}${unit}`}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#1A1A1AA6",
          }}
        >
          {min === null ? "-" : `${min}${unit}`}
        </Typography>
      </div>
    </div>
  );
}
