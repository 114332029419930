import axios from "axios";
import { config } from "@/config";

const interceptorFn = (configuration) => {
  const oktaToken = JSON.parse(
    localStorage.getItem("okta-token-storage") || "{}",
  );
  const path = window.location.pathname;
  if (oktaToken && oktaToken.accessToken?.accessToken) {
    configuration.headers.Authorization = `Bearer ${oktaToken.accessToken.accessToken}`;

    // we are in demo mode... could be proxied at nginx controller, maybe...
    if (path.match(/^\/demo/)) {
      configuration.headers["x-smartfm-demo"] = true;
    }
  }
  return configuration;
};
const axiosConfig = {
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
};

const axiosInstance = axios.create({
  ...axiosConfig,
  baseURL: config.adminServiceUrl,
});

export const api = axios.create({
  ...axiosConfig,
  baseURL: `${config.adminServiceUrl.slice(0, -6)}`,
});

axiosInstance.interceptors.request.use(interceptorFn);
api.interceptors.request.use(interceptorFn);
export default axiosInstance;
