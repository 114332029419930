import React from "react";
import { makeStyles } from "@mui/styles";

import { Card, CardContent, Typography, Box, Stack } from "@mui/material";
import "./RecommendedActions.scss";

const useStyles = makeStyles({
  widgetContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  widgetCard: {
    height: "295px",
    width: "320px",
    padding: "0px !important",
    borderRadius: "8px",
    border: "1px solid #BDA5E5",
    boxShadow: "0px 1px 3px 0px #00000024",
  },
  widgetCardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "0",
  },
  widgetHead: {
    backgroundImage: `radial-gradient(63.73% 63.73% at 58.21% 21.08%, rgba(97, 47, 114, 0.312) 0%, rgba(166, 106, 214, 0.6) 100%),linear-gradient(93.57deg, rgba(48, 54, 90, 0.9) 22.23%, rgba(49, 48, 107, 0.378) 69.7%, rgba(48, 54, 90, 0.81) 87.04%), url('/cardbg.png')`, // cspell:disable-line
    height: "102px",
    backgroundSize: "contain",
  },
  widgetHeadContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 20px",
  },
  widgetHeading1: {
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  widgetDate: {
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  widgetHeading2: {
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  widgetHeading3: {
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
  },
  widgetBody: {
    padding: "24px 20px",
    display: "flex",
    flexDirection: "column",
  },
  widgetBodyText: {
    fontSize: "16px",
    lineHeight: "20px",
    paragraph: "true",
    paddingBottom: "12px",
    display: "block",
    whiteSpace: "pre-wrap",
  },
  widgetBodyTextBold: {
    fontWeight: 500,
  },
  widgetBodyTextBoldRed: {
    fontWeight: 500,
    color: "#FF543E",
  },
  recommendationContainer: {
    borderLeft: "4px solid #8585E0",
    paddingLeft: "12px",
  },
  recommendationHeadingText: {
    fontWeight: 500,
    color: "#8585E0",
  },
  recommendationText: {
    height: "96px",
    overflow: "auto",
  },
  insightUnderline: {
    width: "16px",
    height: "1px",
    background: "#17E88F",
  },
});
const RecommendedActions = ({
  date,
  title,
  assetName,
  alarmCounts,
  issueCount,
  recommendedAction,
}) => {
  const classes = useStyles();
  return (
    <Stack direction="row" spacing={2}>
      <Box className={classes.widgetContainer}>
        <Card className={classes.widgetCard}>
          <CardContent className={classes.widgetCardContent}>
            <Box className={classes.widgetHead}>
              <Box className={classes.widgetHeadContainer}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography className={classes.widgetHeading1}>
                    Insights
                  </Typography>
                  <Typography className={classes.widgetDate}>{date}</Typography>
                </Box>

                <Typography className={classes.widgetHeading2}>
                  {title}
                </Typography>
                <Typography className={classes.widgetHeading3}>
                  Condition Based Maintenance
                </Typography>
              </Box>
            </Box>
            <Box className={classes.widgetBody}>
              <Box>
                <Typography className={classes.widgetBodyText}>
                  <Box
                    component={"span"}
                    className={classes.widgetBodyTextBold}
                  >
                    {assetName}
                  </Box>
                  {` is in poor health with ${alarmCounts} alarm counts with `}
                  <Box
                    component="span"
                    className={classes.widgetBodyTextBoldRed}
                  >
                    {issueCount}.
                  </Box>
                </Typography>
              </Box>

              <Box className={classes.recommendationContainer}>
                <Typography
                  color="textSecondary"
                  className="recommended-actions"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  <Box
                    component={"span"}
                    className={classes.recommendationHeadingText}
                  >
                    {"Recommended Action: "}
                  </Box>
                  {recommendedAction}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Stack>
  );
};

export default RecommendedActions;
